let scriptLoaded = false;
let promise: Promise<void> | null = null;

let richSnippetsScript: ReturnType<typeof useHead>;
let currentRichSnippetProductName: string;
let currentRichSnippetURL: string;

export const stampedInit = (): Promise<void> => {
    const config = useRuntimeConfig();
    if (scriptLoaded) return Promise.resolve();
    if (promise) return promise;
    promise = new Promise((resolve) => {
        useHead({
            script: [
                {
                    vmid: 'StampedIo',
                    type: 'text/javascript',
                    src: `https://cdn1.stamped.io/files/widget.min.js`,
                    onload: async () => {
                        // @ts-ignore
                        StampedFn.init({ apiKey: config.public.stamped.apiKey, sId: config.public.stamped.sId });
                        scriptLoaded = true;
                        resolve();
                    },
                },
            ],
        });
    });
    return promise;
};

export const stampedInitRichSnippets = (productTitle: string, productUrl: string): void => {
    currentRichSnippetProductName = productTitle;
    currentRichSnippetURL = productUrl;

    // Return if the stamped:reviews:loaded event listener is already registered
    if (richSnippetsScript) return;

    document.addEventListener('stamped:reviews:loaded', () => {
        if (richSnippetsScript) return;

        const ratingCount = document.querySelector<HTMLMetaElement>('meta[itemprop="reviewCount"]')?.content ?? '0';
        const ratingValue = document.querySelector<HTMLMetaElement>('meta[itemprop="ratingValue"]')?.content ?? '0';

        const richSnippet: {
            '@context': string;
            '@type': string;
            '@id': string;
            name: string;
            aggregateRating?: {
                '@type': string;
                ratingValue: any;
                reviewCount: any;
            };
        } = {
            '@context': 'http://schema.org',
            '@type': 'Product',
            '@id': currentRichSnippetURL,
            name: currentRichSnippetProductName,
        };

        if (parseInt(ratingValue) > 0) {
            richSnippet.aggregateRating = {
                '@type': 'AggregateRating',
                ratingValue: ratingValue,
                reviewCount: ratingCount,
            };
        }

        richSnippetsScript = useHead({
            script: [
                {
                    type: 'application/ld+json',
                    innerHTML: JSON.stringify(richSnippet),
                    hid: 'stamped-rich-snippets',
                },
            ],
        });
    });
};

export const stampedUnloadRichSnippets = (): void => {
    if (!richSnippetsScript) return;

    richSnippetsScript.dispose();
    richSnippetsScript = undefined;
};

export const checkStars = async (element: HTMLElement, retriesLeft = 50): Promise<void> => {
    if (!retriesLeft) return;
    await wait(100);
    // sometimes we are to quick and stamped is not fully initialized
    try {
        debouncedReloadUGC();
    } catch (error) {
        return await checkStars(element, retriesLeft);
    }
    if (element.querySelector('.stamped-fa-star, .stamped-fa-star-o') === null) {
        return await checkStars(element, retriesLeft--);
    }
};

export const debouncedReloadUGC = debounce(function () {
    window.StampedFn.reloadUGC();
}, 500);
